/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'textSearch',
    'label',
    'counter',
    'sortingDropdown',
    'sortingText',
    'clearBtn',
  ];

  static values = {
    assignmentMatrix: Boolean,
    noAssetsAssigned: Boolean
  };

  _addFiltersCounter() {
    if (!this.hasCounterTarget) return;

    const filterContainer = this.formTarget;
    const filters = Array.from(filterContainer.querySelectorAll('.form-group'));
    const checkedFiltersLength = filters.filter((filter) => filter.querySelector('.form-check input:checked') != null).length;
    if (checkedFiltersLength <= 0) return;

    if (this.assignmentMatrixValue || this.noAssetsAssignedValue) {
      const numberOfAssetTypesAvailable = filterContainer.querySelectorAll('#selected_asset_type_ids_').length;
      const numberOfAssetTypesChecked = filterContainer.querySelectorAll('#selected_asset_type_ids_:checked').length;
      if (numberOfAssetTypesAvailable == numberOfAssetTypesChecked) return;
    }

    this.counterTarget.textContent = ` | ${checkedFiltersLength} Applied`;
    this._showCounterAndStylize();
    this._showFilterIcons();
    this.highlightFilterHeaders();
  }

  _showCounterAndStylize() {
    this.counterTarget.classList.remove('d-none');
    this.labelTarget.classList.remove('border_sm');
    this.clearBtnTarget.classList.remove('d-none');

    this.labelTarget.classList.add('pe-0');
    this.labelTarget.classList.add('border_none');
    this.labelTarget.classList.add('hover-bg-white');
    this.labelTarget.classList.add('active-bg-white');
    this.clearBtnTarget.classList.add('ps-0');
    this.clearBtnTarget.classList.add('border_none');
    this.clearBtnTarget.classList.add('hover-bg-white');
    this.clearBtnTarget.classList.add('active-bg-white');
  }

  highlightFilterHeaders() {
    const filterContainer = this.formTarget;
    const filters = Array.from(filterContainer.querySelectorAll('.offcanvas .form-group'));
    filters.forEach((filter) => this._clearFilterHeader(filter));
    filters.filter((filter) => filter.querySelector('.form-check input:checked') != null)
      .forEach((filter) => {
        this._highlightFilterHeader(filter);
      });
  }

  _highlightFilterHeader(filter) {
    filter.querySelector('.attention-dot').classList.remove('d-none');
  }

  _clearFilterHeader(filter) {
    filter.querySelector('.attention-dot').classList.add('d-none');
  }

  _showFilterIcons() {
    const filterIcons = document.querySelectorAll('.active > span .filterIcon');
    filterIcons.forEach((icon) => icon.classList.remove('d-none'));
  }

  _updateSortButton() {
    if (!this.hasSortingTextTarget) return;

    const selection = this.sortingDropdownTarget.querySelector('option[selected]');
    const hasSelection = selection !== null;

    const sortText = this.sortingTextTarget.dataset.sort;
    const sortBy = hasSelection ? selection.innerHTML : '';

    this.sortingTextTarget.textContent = `${sortText}: ${sortBy}`;
  }

  openSortingDropdown() {
    const control = this.sortingDropdownTarget.tomselect;
    control.open();
  }

  clearAll(e) {
    e.stopImmediatePropagation();
    const params = new URLSearchParams(window.location.search);
    const paramsToDelete = Array.from(params.keys()).filter((key) => key.startsWith('q[') || key === 'search_field' || key.startsWith('selected_asset_type_ids['));
    paramsToDelete.forEach((p) => params.delete(p));
    if (Array.from(params.keys()).length === 0) {
      window.location.href = window.location.pathname;
    } else {
      window.location.href = `${window.location.pathname}?${params.toString()}`;
    }
  }

  clearFilter(e) {
    const clearBtn = e.currentTarget;
    const inputs = clearBtn.closest('.form-group').querySelectorAll('input[type=checkbox], input[type=radio]');
    inputs.forEach((input) => input.checked = false);

    this.highlightFilterHeaders();
  }

  clearTextSearch() {
    this.textSearchTarget.value = '';
  }

  performSearch() {
    this.formTarget.requestSubmit();
  }

  connect() {
    this._updateSortButton();
    this._addFiltersCounter();
  }
}
