import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['text'];

  // NOTE:
  // Cannot test locally, requires HTTPS (window.isSecureContext = true)
  // If navigator is undefined then it is not supported in browser

  async copyContent() {
    if (!this.hasTextTarget) return;

    const hasDataValue = this.textTarget.dataset.value !== undefined;
    const text = hasDataValue ? this.textTarget.dataset.value : this.textTarget.innerHTML;
    await navigator.clipboard.writeText(text);
  }
}
