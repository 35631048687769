import { Controller } from '@hotwired/stimulus';
import { patch } from '../shared/request';

function debounce(func, wait, immediate) {
  let timeout;
  return (...args) => {
    const later = function () {
      timeout = null;
      if (!immediate) func(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...args);
  };
}
const DEBOUNCE_DELAY = 500;

export default class extends Controller {
  static values = { path: String, attributeKey: String };

  static targets = ['form', 'checkbox'];

  connect() {
    this.updateTextDebounced = debounce(
      this.#updateTextRequest.bind(this),
      DEBOUNCE_DELAY,
    );
  }

  #updateTextRequest(text) {
    const [model, attribute] = this.attributeKeyValue.split('.');
    const requestData = { [model]: { [attribute]: text } };
    patch(this.pathValue, requestData);
  }

  updateText({ target: { value: text } }) {
    this.updateTextDebounced(text);
  }

  updateState() {
    this.checkboxTarget.classList.add('disabled');
    this.formTarget.requestSubmit();
  }
}
