/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initOffcanvas() {
    this._preventDuplicatedOffcanvasBackdrop();
    this._hideDropdownMenu();
  }

  initModal() {
    this._preventDuplicatedModalBackdropAboveOffcanvas();
    this._hideDropdownMenu();
  }

  _preventDuplicatedOffcanvasBackdrop() {
    const backdrop = document.querySelector('.offcanvas-backdrop');
    if (backdrop !== null) backdrop.remove();
  }

  _preventDuplicatedModalBackdropAboveOffcanvas() {
    // NOTE: Remove this later after we update offcanvas with turbo-offcanvas
    const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (offcanvasBackdrop !== null) modalBackdrop.remove();
  }

  _hideDropdownMenu() {
    const buttons = document.querySelectorAll('.dropdown-button');
    buttons.forEach((dropdown) => {
      const myDropdown = new bootstrap.Dropdown(dropdown, {});
      myDropdown.hide();
      dropdown.classList.remove('show');
      dropdown.setAttribute('aria-expanded', 'false');
    });
  }
}
