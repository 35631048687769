import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../../locales/translations.json';
import { csrfToken } from '@rails/ujs';
const i18n = new I18n(translations);

export default class extends Controller {
  check() {
    const input = this.element;
    const serialNumber = input.value;
    const warningFeedbackDiv = document.createElement('div');
    warningFeedbackDiv.classList.add('warning-feedback');
    warningFeedbackDiv.innerHTML = i18n.t('activerecord.errors.models.device.attributes.serial_number.duplicated');

    const formGroup = input.closest('.form-group');
    const url = `/physical_assets/${serialNumber}/check_serial_number_uniq`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      }
    }).then((response) => {
      console.log(response.status);
      if (response.status === 200) {
        input.classList.add('with-warning');
        if (formGroup.querySelectorAll('.warning-feedback').length === 0) {
          formGroup.appendChild(warningFeedbackDiv);
        }
        return;
      }
      input.classList.remove('with-warning');
      const warningFeedback = formGroup.querySelector('.warning-feedback');
      if (warningFeedback?.parentNode) warningFeedback.parentNode.removeChild(warningFeedback);
    }).catch(() => {
      input.classList.remove('with-warning');
      const warningFeedback = formGroup.querySelector('.warning-feedback');
      if (warningFeedback?.parentNode) warningFeedback.parentNode.removeChild(warningFeedback);
    })
  }
}
