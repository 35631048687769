import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  static values = { url: String };

  connect() {
    Turbo.cache.clear();
    Turbo.visit(this.urlValue);
    this.element?.remove();
  }
}
