/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import { autocomplete } from '@algolia/autocomplete-js';
import { LRUCache } from 'lru-cache';

// NOTE: Some details about debouncing Algolia autocomplete with LRUCache
// https://github.com/algolia/autocomplete/issues/844#issuecomment-996068521
// ---
// The official recommendation will work if we switch to algolia search client
// https://www.algolia.com/doc/ui-libraries/autocomplete/guides/debouncing-sources/

const options = {
  max: 100,
  ttl: 1000 * 60 * 5,
  allowStale: true,
};

const abortControllers = [];
const cache = new LRUCache(options);
const emptyData = {
  users: [{ name: '...' }],
  devices: [{ name: '...' }],
  apps: [{ name: '...' }],
  licenses: [{ name: '...' }],
  usersLeftCounter: { text: '', url: '', zero: true },
  devicesLeftCounter: { text: '', url: '', zero: true },
  appsLeftCounter: { text: '', url: '', zero: false },
  licensesLeftCounter: { text: '', url: '', zero: true },
};

async function fetchAndCache(url) {
  while (abortControllers.length > 0) {
    const controller = abortControllers.pop();
    controller.abort();
  }

  const cachedData = cache.get(url);
  if (cachedData) return cachedData;

  const currentAbortController = new AbortController();
  abortControllers.push(currentAbortController);

  try {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const response = await fetch(url, { signal: currentAbortController.signal });
    if (response.ok) {
      const data = await response.json();
      cache.set(url, data);
      return data;
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      return emptyData;
    }
  }

  throw new Error(`Failed to fetch data from ${url}`);
}

export default class extends Controller {
  static targets = ['searchContainer'];

  static values = {
    placeholder: String,
    users: String,
    assets: String,
    apps: String,
    licenses: String,
    noresult: String,
    path: String,
  };

  _addDeviceScanAssetLabelController() {
    const inputs = Array.from(document.querySelectorAll('#autocomplete input[id^=autocomplete-]'));
    inputs.forEach((elem) => {
      elem.setAttribute('data-controller', 'device--scan-asset-label');
      elem.setAttribute('data-action', 'scan->device--scan-asset-label#removeSubstring');
      elem.setAttribute('data-device--scan-asset-label-target', 'input');
      elem.setAttribute('data-phone-scanner-target', 'input');
    });
  }

  connect() {
    const users = this.usersValue;
    const assets = this.assetsValue;
    const apps = this.appsValue;
    const licenses = this.licensesValue;
    const noresult = this.noresultValue;
    const path = this.pathValue;

    const config = {
      container: this.searchContainerTarget,
      placeholder: this.placeholderValue,
      getSources({ query }) {
        const encodedQuery = encodeURIComponent(query);
        const apiUrl = `${path}.json?s=${encodedQuery}`;
        return fetchAndCache(apiUrl)
          .then((result) => [
            {
              sourceId: 'users',
              getItems() {
                return result.users;
              },
              getItemUrl({ item }) {
                return item.url;
              },
              getItemInputValue({ item }) {
                return item.name;
              },
              templates: {
                header({ createElement }) {
                  return createElement('span', { className: 'aa-SourceHeaderTitle' }, users);
                },
                item({ item, createElement }) {
                  return createElement('a', { href: item.url, className: 'stretched-link' }, item.name);
                },
                footer({ createElement }) {
                  if (result.usersLeftCounter.zero) return '';
                  return createElement('a', { href: result.usersLeftCounter.url, className: 'd-block text-decoration-none mt-2 mb-3' }, result.usersLeftCounter.text);
                },
                noResults() {
                  return noresult;
                },
              },
            },
            {
              sourceId: 'devices',
              getItems() {
                return result.devices;
              },
              getItemUrl({ item }) {
                return item.url;
              },
              getItemInputValue({ item }) {
                return item.name;
              },
              templates: {
                header({ createElement }) {
                  return createElement('span', { className: 'aa-SourceHeaderTitle' }, assets);
                },
                item({ item, createElement }) {
                  return createElement('a', { href: item.url, className: 'stretched-link' }, item.name);
                },
                footer({ createElement }) {
                  if (result.devicesLeftCounter.zero) return '';
                  return createElement('a', { href: result.devicesLeftCounter.url, className: 'd-block text-decoration-none mt-2 mb-3' }, result.devicesLeftCounter.text);
                },
                noResults() {
                  return noresult;
                },
              },
            },
            {
              sourceId: 'apps',
              getItems() {
                return result.apps;
              },
              getItemUrl({ item }) {
                return item.url;
              },
              getItemInputValue({ item }) {
                return item.name;
              },
              templates: {
                header({ createElement }) {
                  return createElement('span', { className: 'aa-SourceHeaderTitle' }, apps);
                },
                item({ item, createElement }) {
                  return createElement('a', { href: item.url, className: 'stretched-link' }, item.name);
                },
                footer({ createElement }) {
                  if (result.appsLeftCounter.zero) return '';
                  return createElement('a', { href: result.appsLeftCounter.url, className: 'd-block text-decoration-none mt-2 mb-2' }, result.appsLeftCounter.text);
                },
                noResults() {
                  return noresult;
                },
              },
            },
            {
              sourceId: 'licenses',
              getItems() {
                return result.licenses;
              },
              getItemUrl({ item }) {
                return item.url;
              },
              getItemInputValue({ item }) {
                return item.name;
              },
              templates: {
                header({ createElement }) {
                  return createElement('span', { className: 'aa-SourceHeaderTitle' }, licenses);
                },
                item({ item, createElement }) {
                  return createElement('a', { href: item.url, className: 'stretched-link' }, item.name);
                },
                footer({ createElement }) {
                  if (result.licensesLeftCounter.zero) return '';
                  return createElement('a', { href: result.licensesLeftCounter.url, className: 'd-block text-decoration-none mt-2 mb-2' }, result.licensesLeftCounter.text);
                },
                noResults() {
                  return noresult;
                },
              },
            },
          ]);
      },
      onSubmit({ state, e }) {
        const inputs = Array.from(document.querySelectorAll('#autocomplete input[id^=autocomplete-]'));
        const [query] = inputs.filter((elem) => elem.value !== '').map((elem) => elem.value);
        const encodedQuery = encodeURIComponent(query);
        window.location.href = `${path}?s=${encodedQuery}`;
      },
    };
    this.ac = autocomplete(config);
    this._addDeviceScanAssetLabelController();
  }

  disconnect() {
    this.ac.destroy();
  }
}
