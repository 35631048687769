/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggleLeaseRelatedFields({ target }) {
    const leaseExpiryDateSelect = target.form.elements.namedItem('device_lease_expiry_date');
    leaseExpiryDateSelect.disabled = target.value !== 'leased';
    leaseExpiryDateSelect.classList.toggle('disabled', leaseExpiryDateSelect.disabled);

    const leasingAgreementNumber = target.form.elements.namedItem('device_leasing_agreement_number');
    leasingAgreementNumber.disabled = target.value !== 'leased';
    leasingAgreementNumber.classList.toggle('disabled', leasingAgreementNumber.disabled);
    leasingAgreementNumber.disabled ? leasingAgreementNumber.value = '' : leasingAgreementNumber.focus();
  }
}
