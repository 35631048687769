/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jstz from 'jstz';
import Cookies from 'js-cookie';
import 'chartkick/chart.js';
import '@hotwired/turbo-rails';

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('trix');
require('@rails/actiontext');
global.jQuery = global.$ = require('jquery');
window.TomSelect = require('tom-select');
require('../init/stimulus');
require('@nathanvda/cocoon');
require('jquery-ui');
require('@popperjs/core/dist/cjs/popper');
global.bootstrap = require('bootstrap');
require('easy-autocomplete/dist/jquery.easy-autocomplete.min');
require('../user');
require('../device_assignment');
require('bootstrap-datepicker');
require('./turbo_progress_bar');

global.tempusDominius = require('@eonasdan/tempus-dominus/dist/js/tempus-dominus');

const timezone = jstz.determine();
Cookies.set('browser_time_zone', timezone.name(), { expires: 1, path: '/' });

const preventInvalidFormFromSubmit = function () {
  window.addEventListener(
    'load',
    function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      const validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          'submit',
          function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          },
          false,
        );
      });
    },
    false,
  );
};

document.addEventListener('turbo:render', () => {
  preventInvalidFormFromSubmit();

  // TODO: remove all these collapse-* after remote_collection_select refactoring
  $('#collapse-license').focusout(function () {
    $('#collapse-license').removeClass('show');
  });

  $('#collapse-user').focusout(function () {
    $('#collapse-user').removeClass('show');
  });

  $('#collapse-device').focusout(function () {
    $('#collapse-device').removeClass('show');
  });

  $('.submenu').click((e) => e.stopPropagation());

  const body = document.querySelector('body');
  body.addEventListener('hide.bs.modal', function () {
    body.style.setProperty('overflow', 'auto');
    body.removeAttribute('data-bs-overflow');
  });
  $('.modal').on('shown.bs.modal', function () {
    $(this).find('[autofocus]').focus();
  });
});


window.getUrlParameter = function (name) {
  const urlParams = new URLSearchParams(location.search);
  const value = urlParams.get(name);
  return value === null ? '' : value;
};

document.addEventListener('turbo:load', function (event) {
  const frame = event.target;
  if (frame && frame.id === 'nolt-roadmap-frame') {
    initializeNoltRoadmap();
  }
});

Turbo.setConfirmMethod((message) => {
  const turboConfirm = document.getElementById('turbo-confirm');
  const turboConfirmForm = document.getElementById('turbo-confirm-form');
  document.getElementById('turbo-confirm-text').innerHTML = message;

  const offcanvas = bootstrap.Offcanvas.getInstance(turboConfirm)
      || new bootstrap.Offcanvas(turboConfirm);
  offcanvas.show();

  return new Promise((resolve) => {
    turboConfirmForm.addEventListener('submit', (event) => {
      event.preventDefault();
      offcanvas.hide();
      resolve(event.submitter.value === 'accept');
    }, { once: true });
  });
});

document.addEventListener('turbo:before-fetch-request', (event) => {
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = document.querySelector("meta[name='csp-nonce']")?.content;
});

document.addEventListener('turbo:before-cache', () => {
  document.querySelectorAll('script[nonce]').forEach((element) => {
    element.setAttribute('nonce', element.nonce);
  });
});
