/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'select',
    'search',
    'toggleBtn',
    'frame',
    'multipleSelectList',
    'placeholder',
    'label',
    'dropdownItem',
  ];

  static values = {
    placeholder: String,
    scopeName: String,
    multiple: Boolean,
    multipleSelections: Array,
    openAfterSelectionExternal: Boolean,
    findOptionsPath: String,
  };

  initialize() {
    this.state = 'clean';
    this.openAfterSelection = false; // NOTE: 20230508 Probably legacy and not used anymore
  }

  focusOnFirstItem(e) {
    if (e.key === 'ArrowDown') {
      this.frameTarget.querySelector('.dropdown-item').focus();
    }
  }

  setSelected() {
    const selectionJson = this
      .frameTarget
      .querySelector('[data-dropdown-select-selected-value]')
      .getAttribute('data-dropdown-select-selected-value');
    const selectionsRaw = JSON.parse(selectionJson);
    if (selectionsRaw == {} || selectionsRaw === 'null' || selectionsRaw === null || selectionJson === '{}') return;

    if (this.state === 'clean') {
      const selections = Array.isArray(selectionsRaw) ? this._prepareSelections(selectionsRaw) : this._prepareSelection(selectionsRaw);
      this.multipleValue ? this._selectMultipleOptions(selections) : this._selectSingleOption(selections);
      const changeEvent = new Event('change');
      this.selectTarget.dispatchEvent(changeEvent);
    }

    this._hideSelectedOptions();
  }

  _prepareSelections(selections) {
    const self = this;
    return selections.map((selection) => self._prepareSelection(selection));
  }

  _prepareSelection(selection) {
    return {
      value: selection.value,
      text: selection.text,
      others: selection.others ? JSON.parse(selection.others) : {},
    };
  }

  select(e) {
    e.preventDefault();
    e.stopPropagation();
    const { dataset } = e.currentTarget;
    const dataValues = this._prepareSelection(dataset);
    const detailValues = e.detail;
    const selection = { ...dataValues, ...detailValues };

    this._select(selection);
  }

  _select(selection) {
    this.multipleValue ? this._selectMultipleOption(selection) : this._selectSingleOption(selection);
    this.resetSearch();

    const changeEvent = new Event('change');
    this.selectTarget.dispatchEvent(changeEvent);

    if (this.openAfterSelectionExternalValue) this.openAfterSelection = true;
    this._hideDropdown();
  }

  focusSearch() {
    this.searchTarget.disabled = false;
    this.searchTarget.focus();
  }

  resetSearch() {
    this.searchTarget.value = '';
  }

  openDropdown() {
    if (this.openAfterSelection) {
      this.toggleBtnTarget.click();
      this.openAfterSelection = false;
    }
  }

  search() {
    const searchText = this.searchTarget.value;
    const frame = this.frameTarget;
    const srcUrl = new URL(frame.src, window.location.origin);
    srcUrl.searchParams.set('search', searchText);
    frame.src = `${srcUrl.pathname}${srcUrl.search}`;
  }

  _hideOption(option) {
    option.classList.add('d-none', 'visually-hidden');
  }

  _hideSelectedOptions() {
    if (!this.multipleValue) return;

    const values = this.multipleSelectionsValue;
    this.dropdownItemTargets.forEach((option) => {
      const hasValue = values.includes(Number(option.dataset.value));
      if (hasValue) this._hideOption(option);
    });
  }

  removeSingleSelection(e) {
    e.preventDefault();
    e.target.remove();
    this.clear();
    this._selectBlankOption();
  }

  removeMultipleSelection(e) {
    e.preventDefault();
    const li = e.target.closest('li');
    const { optionValue } = li.dataset;
    li.remove();

    const option = this.selectTarget.querySelector(`option[value="${optionValue}"]`);
    option.remove();
    this.multipleSelectionsValue = this.multipleSelectionsValue.filter((value) => value != optionValue);
    this._hideDropdown();
    const changeEvent = new Event('change');
    this.selectTarget.dispatchEvent(changeEvent);
    if (this.multipleSelectionsValue.length > 0) return;

    this._selectBlankOption();
  }

  selectByQuery({ detail }) {
    // eslint-disable-next-line no-param-reassign
    detail = detail || {};
    const queryParams = Object.fromEntries(
      Object.entries(detail).map(([k, v]) => ([`query[${k}]`, v])),
    );
    const query = { ...queryParams, scope_name: this.scopeNameValue };

    const self = this;
    Rails.ajax({
      url: `${this.findOptionsPathValue}?${new URLSearchParams(query)}`,
      type: 'GET',
      success({ options }) {
        if (options.length === 1) {
          self._select(options[0]);
          return;
        }

        self.dispatch('error', { detail: { type: 'danger', text: 'No options found' } });
        console.error('[DropdownSelectController] Failed auto select', options);
      },
      error(data) {
        self.dispatch('error', { detail: { type: 'danger', text: 'Something went wrong. Try again' } });
        console.error('[DropdownSelectController] Failed auto select request', data);
      },
    });
  }

  _addClearButton() {
    if (this.multipleValue) return;

    const clearBtn = document.createElement('i');
    clearBtn.classList.add('bi', 'bi-x-circle', 'text-danger', 'clearBtn');
    clearBtn.dataset.action = 'click->dropdown-select#removeSingleSelection';
    this.toggleBtnTarget.querySelector('.clearBtn')?.remove();
    this.toggleBtnTarget.appendChild(clearBtn);
    this.toggleBtnTarget.classList.add('remote_collection_select__wrapper-selected');
    this.toggleBtnTarget.classList.remove('dropdown-toggle');
  }

  clear() {
    this._clear();
    const changeEvent = new Event('change');
    this.selectTarget.dispatchEvent(changeEvent);
  }

  _clear() {
    this.selectTarget.innerHTML = '';
    this.labelTarget.innerText = '';
    this.labelTarget.classList.add('d-none');
    this.placeholderTarget.classList.remove('d-none');
    this.toggleBtnTarget.classList.remove('remote_collection_select__wrapper-selected');
    this.state = 'clean';
  }

  _selectBlankOption() {
    const option = document.createElement('option');
    option.value = '';
    option.innerText = this.placeholderValue;
    option.selected = true;
    this.selectTarget.replaceChildren(option);
    this.labelTarget.innerText = '';

    this.labelTarget.classList.add('d-none');
    this.toggleBtnTarget.classList.add('dropdown-toggle');
    this.toggleBtnTarget.classList.remove('remote_collection_select__wrapper-selected');
    this.placeholderTarget.classList.remove('d-none');
    this.state = 'selected';
  }

  _createPill(text, value, options) {
    const li = document.createElement('li');
    const cross = document.createElement('span');
    const label = document.createElement('span');

    cross.innerText = '×';
    label.innerText = text;

    li.classList.add('selection__list__choice');
    cross.classList.add('selection__list__choice__remove');
    label.classList.add('label');

    li.dataset.optionValue = value;
    cross.dataset.action = options.action;

    li.appendChild(cross);
    li.appendChild(label);
    this.multipleSelectListTarget.appendChild(li);
  }

  _createOption(value, text, others = {}) {
    const option = document.createElement('option');
    option.value = value;
    option.innerText = text;
    option.selected = true;

    // FIXME: Requires refactoring, prototype iteration ahead
    if (others) {
      for (const key in others) {
        option.dataset[key] = others[key];
      }
    }
    this.selectTarget.appendChild(option);
  }

  _selectSingleOption(selection) {
    const { value, others } = selection;
    const text = Array.isArray(selection.text) ? selection.text[0] : selection.text;

    this.selectTarget.innerHTML = '';
    this.labelTarget.innerText = text;
    this._createOption(value, text, others);
    if (value !== '') this._addClearButton();
    this.placeholderTarget.classList.add('d-none');
    this.labelTarget.classList.remove('d-none');
    this.state = 'selected';
  }

  _selectMultipleOption(selection) {
    const action = 'click->dropdown-select#removeMultipleSelection';
    const value = Number(selection.value);
    const values = this.multipleSelectionsValue;
    const hasValue = values.includes(value);
    if (hasValue) return;

    values.push(value);
    this.multipleSelectionsValue = values;

    const text = Array.isArray(selection.text) ? selection.text[0] : selection.text;
    this._createOption(value, text);
    this._createPill(text, value, { action });
    this.toggleBtnTarget.classList.remove('dropdown-toggle');
    this.placeholderTarget.classList.add('d-none');
    this.state = 'selected';
  }

  _selectMultipleOptions(selections) {
    if (selections.length === 0) return;

    selections.forEach((selection) => {
      this._selectMultipleOption(selection);
    });
  }

  _hideDropdown() {
    const dropdown = new bootstrap.Dropdown(this.toggleBtnTarget);
    dropdown.hide();
  }
}
